@font-face {
font-family: '__primaryFontVariable_b5acc1';
src: url(/_next/static/media/0c49d2dee85f0eaf-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__primaryFontVariable_Fallback_b5acc1';src: local("Arial");ascent-override: 57.59%;descent-override: 16.01%;line-gap-override: 14.72%;size-adjust: 135.87%
}.__className_b5acc1 {font-family: '__primaryFontVariable_b5acc1', '__primaryFontVariable_Fallback_b5acc1';font-weight: 500;font-style: normal
}

